import styled from "styled-components";

type FormComponentWrapperProps = {
    withoutGaps?: boolean;
};

export const FormComponentContainer = styled.div<FormComponentWrapperProps>`
    display: flex;
    flex-direction: column;
    padding: 0 28px;
    gap: ${props => !props.withoutGaps && "4px"};
    color: #2B2B2B;
`;
