import type { IconProps } from "./types";

export const ChevronDown = (props: IconProps) => {
  return (
    <svg 
      onClick={props.onClick}
      style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.64645 5.14645C1.84171 4.95118 2.15829 4.95118 2.35355 5.14645L8 10.7929L13.6464 5.14645C13.8417 4.95118 14.1583 4.95118 14.3536 5.14645C14.5488 5.34171 14.5488 5.65829 14.3536 5.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L4.64645 8.85355L1.64645 5.85355C1.45118 5.65829 1.45118 5.34171 1.64645 5.14645Z" fill="#1E1E1E"/>
    </svg>
  );
};
