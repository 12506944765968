import { AlertText } from "./styles";
import { AlertTextContainer } from "./styles";
import { AlertInnerWrap } from "./styles";
import { AlertContentContainer } from "./styles";
import { FormComponentContainer } from "../../../components/style";
import { AlertWrapper } from "./styles";
import { AlertIcon } from "../../../components/Icons/AlertIcon";

enum BGC_STATUS {
    IdNumberInvalid = "Invalid ID",
    AccountNumberInvalid = "Account number invalid",
    AccountNumberMissing = "Account number missing",
    ClearingNumberInvalidOrMissing = "Clearing number invalid or missing",
    BankNotParticipating = "Bank not participating",
    AccountIsTerminated = "Terminated account(s)",
    NoResponseFromBank = "No response from bank",
    ReasonNotSpecified = "Reason not specified",
    IncorrectOwner = "Incorrect owner",
    NotRegistered = "Account number not registered",
    AwaitingCheck = "Awaiting check"
  }
  
const mapBgcStatus = (status: string): string => {
    if (!status)
        return ""

    const foundKey = Object.keys(BGC_STATUS).find((key) => key === status)
    const foundValue = foundKey ? BGC_STATUS[foundKey as keyof typeof BGC_STATUS] : null

    if (foundValue)
        return foundValue

    return ""
}

type BgcAlertProps = {
    bgcStatus: string
}

export const ManualBGCAlert = ({ bgcStatus}: BgcAlertProps) => {
  return (
    <FormComponentContainer>
          <AlertWrapper>
            <AlertContentContainer>
              <AlertInnerWrap>
                <AlertIcon />
              </AlertInnerWrap>
              <AlertInnerWrap>
                <AlertTextContainer>
                  <AlertText>{mapBgcStatus(bgcStatus)}</AlertText>
                </AlertTextContainer>
              </AlertInnerWrap>
            </AlertContentContainer>
          </AlertWrapper>
        </FormComponentContainer>
  )
}
