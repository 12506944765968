import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import type { ComponentConfig } from "../types";
import { useState, useEffect } from 'react';
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";
import { FormText } from "../../../components/Input/FormText";
import { ManualBGCAlert } from "./ManualBGCAlert";
import { AccountInfoHeader } from "./styles";
import { TinkAccount } from "./types";
import { AccountInfo } from "./AccountInfo";

const ManualCheckBgc = (props: any) => {
  const { flow } = props;
  const { bgc } = flow?.data?.integrations?.leabank || {};
  const loadedDecision = props?.task?.data.shouldRetry;
  const loadedAccountNumber = props?.task?.data?.accountNumber ?? bgc?.request?.bankAccountNumber ?? "";
  const loadedComment = props?.task?.data?.comment || "";
  const hasCoApplicant = !!flow?.data?.agentApiApplication?.coapplicant
  const bgcStatus = bgc?.response?.Status || ""
  const tinkAccounts: TinkAccount[] = flow?.data?.integrations?.tink?.accounts || [];

  // States
  const [decision, setDecision] = useState<boolean | null>(loadedDecision);
  const [accountNumber, setAccountNumber] = useState<string>(loadedAccountNumber);  
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(loadedComment);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [closedIds, setClosedIds] = useState<number[]>([]);

  const applicantInfo = flow?.data?.agentApiApplication?.applicant
  const coApplicantInfo = flow?.data?.agentApiApplication?.coapplicant ? {
    ...flow?.data?.agentApiApplication?.coapplicant,
    isCoApplicant: true,
  } : null;

  useEffect(() => {
    // TODO: Figure out which rule to use for validating accountNumber
    // if (accountNumber.length < 10 && accountNumber.length > 0 && accountNumberError === false) {
    //   console.log("Error")
    //   setAccountNumberError(true)
    // }

    // if ((accountNumber.length >= 10 || accountNumber.length === 0) && accountNumberError === true) {
    //   console.log("Not error")
    //   setAccountNumberError(false)
    // }
  }, [accountNumber, accountNumberError, setAccountNumberError])

  const onComplete = () => {

    if (decision === null) {
      setErrorMessages("Please select a decision");
      return;
    }

    if (accountNumber === null) {
      setErrorMessages("Please set a accountNumber")
    }

    const formData = {
      accountNumber,
      shouldRetry: decision,
      comment,
    };

    props.complete(
      formData,
      () => { },
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    );
  }
  const onSave = () => {
    const formData = {
      accountNumber,
      shouldRetry: decision,
      comment,
    };
    props.save(formData,
      () => console.log("success"),
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    )
  }

  const handleSections = (id: number) => {
    const found = closedIds.includes(id);
    if (found) {
      setClosedIds(closedIds.filter(cid => cid !== id));
    } else {
      setClosedIds([...closedIds, id]);
    }
  }

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <div style={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }}>
          <ApplicantInfo applicant={applicantInfo} />
          {hasCoApplicant && <ApplicantInfo applicant={coApplicantInfo} />}
        </div>
      ),
      order: 1,
    },
    {
      component: (
        <ManualBGCAlert bgcStatus={bgcStatus} />
      ),
      order: 2,
    },
    {
      component: (
        <FormText 
          label="Account number"
          subLabel="Account number used for bank giro check"
          name="accountNumber"
          onChange={(e: string) => setAccountNumber(e)}
          defaultValue={accountNumber || ""}
          hasError={accountNumberError}
          errorMessage="Wrong format"
        />
      ),
      order: 3,
    },
    {
      component: (
        <RadioButtonGroup
          radioButtonValue={decision}
          onChange={(e: string) =>
            e === "true" ? setDecision(true) : setDecision(false)
          }
          title="Decision"
          options={[
            { value: true, label: "Retry BGC" },
            { value: false, label: "Reject application" },
          ]}
          id="check-manual-handling"
        />
      ),
      order: 4,
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment || ""}
          placeholder="Other applicant information"
        />
      ),
      order: 5,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 6,
      hide: errorMessages === "",
    },
  ];

  const infoConfig: ComponentConfig[] | undefined = [];

  if (tinkAccounts.length > 0) {
      infoConfig.push({
        component: (
          <AccountInfoHeader>Accounts found (TINK)</AccountInfoHeader>
        ),
        order: 0,
      });

    tinkAccounts.map((account, index) => {
      infoConfig.push({
        component: (
          <AccountInfo 
            id={account.id}
            accountNumber={account.accountNumber}
            name={account.name}
            financialInstitutionName={account.financialInstitutionName}
            holderNames={account.holderNames}
            iban={account.iban}
            users={account.users}
            isFirst={index === 0}
          />
        ),
        order: index + 1
      })
    })
  }


  return (
    <CommonUserTaskNew
      handleComplete={onComplete}
      handleSave={onSave}
      infoConfig={infoConfig.length > 0 ? infoConfig : undefined }
      formConfig={formConfig}
      overrideMasonryColumns={1}
      disableButtons={decision === null || accountNumber === null}
    />
  );
};

export default ManualCheckBgc;
