export function toPhoneNumberSe(inputMobileNumber: string): string {
    const phoneNumber = inputMobileNumber.replace(/\s+/gi, '');

    if (phoneNumber.startsWith('+')) {
        return phoneNumber;
    }

    const countryCode = '46';
    const countryCodePrefix = '+' + countryCode;

    if (phoneNumber.startsWith('00')) {
        return `+${phoneNumber.slice(2)}`;
    }
    if (phoneNumber.startsWith(countryCode) && phoneNumber.length > 8) {
        return '+' + phoneNumber;
    }

    if (!phoneNumber.startsWith(countryCodePrefix)) {
        return countryCodePrefix + phoneNumber.replace(/^0+/, '');
    }

    return phoneNumber;
}