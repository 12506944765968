import type { IconProps } from "./types";

export const ChevronUp = (props: IconProps) => {
  return (
    <svg 
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L14.3536 10.1464C14.5488 10.3417 14.5488 10.6583 14.3536 10.8536C14.1583 11.0488 13.8417 11.0488 13.6464 10.8536L8 5.20711L2.35355 10.8536C2.15829 11.0488 1.84171 11.0488 1.64645 10.8536C1.45118 10.6583 1.45118 10.3417 1.64645 10.1464L7.64645 4.14645Z" fill="#1B4DF5"/>
    </svg>
  );
};
