import styled from "styled-components";

const DividerDiv = styled.div`
    height: 1px;
    align-self: stretch;
    border: 1px solid var(--stroke-primary, #B8C4E0);
`;

export const Divider = () => {
    return <DividerDiv />;
};