import styled from "styled-components";
import { InfoSectionWrapper, DecisionSectionDivWrapper, FlexWrapper, DecisionSectionWrapper } from "../styles";

export const SMSConversationWrapper = styled(InfoSectionWrapper)`
    overflow: auto;
    //Hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const CallSectionWrapper = styled(DecisionSectionDivWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 15px 40px rgb(184 184 184 / 20%), 0px 5px 10px rgb(184 184 184 / 5%);
`;

export const ListItem = styled.li`
  cursor: pointer;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;

  //On hover change background color
  &:hover {
    background-color: #d3dfe5;
    color: black;
  }
`;

export const IconWrapper = styled(FlexWrapper)`
  transform: rotate(-125deg);

  path {
        fill: #467EFF;
    }
`;


export const StyledDecisionSectionWrapper = styled(DecisionSectionWrapper)`
  display: flex;
  background-color: #e57373;
  justify-content: center;
  box-shadow: 0px 15px 40px rgb(184 184 184 / 20%), 0px 5px 10px rgb(184 184 184 / 5%);
  border-radius: 5px;
  padding: 10px;
`

export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  grid-gap: 5px;
  color: white;
  font-size: 12px;
`;