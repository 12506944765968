import { AccountRowContent,AccountHeader,  AccountRow, AccountInfoWrapper, AccountContainer, AccountHeaderWrapper } from "./styles";
import { TinkAccount } from "./types";
import { ChevronDown } from "../../../components/Icons/ChevronDown";
import { ChevronUp } from "../../../components/Icons/ChevronUp";
import { useState } from 'react';

type AccountInfoProps = TinkAccount & { isFirst?: boolean };

export const AccountInfo = ({ id, accountNumber, name, financialInstitutionName, holderNames, iban, users, isFirst }: AccountInfoProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(isFirst ? false : true);

  return (
    <AccountContainer>
      <AccountHeaderWrapper>
        <AccountHeader>{name} (Nr. {accountNumber})</AccountHeader>
        {isClosed ? <ChevronDown onClick={() => setIsClosed(!isClosed)} /> : <ChevronUp onClick={() => setIsClosed(!isClosed)} />}
      </AccountHeaderWrapper>
      {!isClosed && (
        <AccountInfoWrapper>
          <AccountRow>
            <AccountRowContent>Financial institution</AccountRowContent>
            <AccountRowContent>{financialInstitutionName}</AccountRowContent>
          </AccountRow>
          <AccountRow>
            <AccountRowContent>Account number</AccountRowContent>
            <AccountRowContent>{accountNumber}</AccountRowContent>
          </AccountRow>
          <AccountRow>
            <AccountRowContent>Account name</AccountRowContent>
            <AccountRowContent>{name}</AccountRowContent>
          </AccountRow>
          <AccountRow isLast>
            <AccountRowContent>Account holder(s)</AccountRowContent>
            <AccountRowContent>{holderNames.join(", ")}</AccountRowContent>
          </AccountRow>
      </AccountInfoWrapper>
      )}
    </AccountContainer>
  )
}
    