import { AgentApiApplication, Country, Income } from "../../types/common";
import { NormalInfoWrapper } from "../../views/tasks/styles";
import { formatCurrency } from "../../utils/helperFunctions";
import { ADCASubSection, SubTitle, ApplicationDetailsContainer, ApplicationDetailsHeader, ApplicationDetailsTitle, ApplicationDetailsContent, ApplicationDetailsContentWrapper, ApplicationDetailsContentMainSection, ApplicationDetailsContentApplicationSection } from "./styles";

type ApplicationDetailsProps = {
    agentApiApplication: AgentApiApplication;
    country: Country;
};

const ApplicationDetailsIncome = (income: Income[], country: Country, isMainApplicant: boolean = true) => {
    const grossIncome = income?.filter((i) => i.type === "GrossIncome")[0]?.amount;
    const netIncome = income?.filter((i) => i.type === "NetIncome")[0]?.amount;

    return (
        <ADCASubSection>
            <SubTitle>{isMainApplicant ? "Main applicant" : "Co-applicant"}</SubTitle>
            <NormalInfoWrapper style={{ width: "-webkit-fill-available" }}>
                <div>Monthly gross income</div>
                <div>{formatCurrency(grossIncome ?? 0, country)}</div>
            </NormalInfoWrapper>
            <NormalInfoWrapper style={{ width: "-webkit-fill-available" }} $isLast>
                <div>Monthly net payout</div>
                <div>{formatCurrency(netIncome ?? 0, country)}</div>
            </NormalInfoWrapper>
        </ADCASubSection>
    );
}

export const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
    agentApiApplication,
    country
}: Partial<ApplicationDetailsProps>) => {
    const { applicant, coapplicant, application } = agentApiApplication ?? {};
    const { appliedAmount, refinanceAmount, appliedRepaymentTime } = application ?? {};
    const { income } = applicant ?? {};
    const { income: incomeCoapplicant } = coapplicant ?? {};

    return (
        <ApplicationDetailsContainer>
            <ApplicationDetailsHeader>
                <ApplicationDetailsTitle>Application Details</ApplicationDetailsTitle>
            </ApplicationDetailsHeader>
            <ApplicationDetailsContent>
                <ApplicationDetailsContentWrapper>
                    <ApplicationDetailsContentMainSection>
                        <NormalInfoWrapper style={{ width: "-webkit-fill-available" }}>
                            <div>Applied amount</div>
                            <div>{formatCurrency(appliedAmount ?? 0, country)}</div>
                        </NormalInfoWrapper>
                        <NormalInfoWrapper style={{ width: "-webkit-fill-available" }}>
                            <div>Refinance amount</div>
                            <div>{formatCurrency(refinanceAmount ?? 0, country)}</div>
                        </NormalInfoWrapper>
                        <NormalInfoWrapper style={{ width: "-webkit-fill-available" }} $isLast>
                            <div>Repayment period</div>
                            <div>{appliedRepaymentTime ?? 0} months</div>
                        </NormalInfoWrapper>
                    </ApplicationDetailsContentMainSection>
                    <ApplicationDetailsContentApplicationSection>
                        {ApplicationDetailsIncome(income ?? [], country ?? "SE" as Country)}
                        {coapplicant && ApplicationDetailsIncome(incomeCoapplicant ?? [], country ?? "SE" as Country, false)}
                    </ApplicationDetailsContentApplicationSection>
                </ApplicationDetailsContentWrapper>
            </ApplicationDetailsContent>
        </ApplicationDetailsContainer>
    );
};