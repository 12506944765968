import styled from "styled-components";

export const ApplicationDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--radius-100, 8px);
    border: 1px solid var(--stroke-secondary, #D4DCEC);
    background: #F8F9FC;
`;

export const ApplicationDetailsHeader = styled.div`
    display: flex;
    height: 40px;
    padding: var(--space-100, 8px) var(--space-200, 16px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const ApplicationDetailsTitle = styled.h4`
    color: var(--content-default, #1E1E1E);

    /* Default Strong */
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
`;

export const ApplicationDetailsContent = styled.div`
    display: flex;
    min-width: 550px;
    padding: var(--space-200, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-0, 0px);
    align-self: stretch;
    border: 1px solid var(--stroke-secondary, #D4DCEC);
    background: var(--surface-default, #FFF);
`;

export const ApplicationDetailsContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-300, 24px);
    align-self: stretch;
`;

export const ApplicationDetailsContentMainSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export const ApplicationDetailsContentApplicationSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
`;

export const ADCASubSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const SubTitle = styled.h5`
    color: var(--content-secondary, #6E7686);

    /* Small Strong */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 16.8px */
`;