import axios from "axios";
import { getAndCacheToken } from "./service.helper";
import { toPhoneNumberSe } from "../utils/itx.helper";

interface CallApplicantProps {
  to: string;
  country?: "ES" | "SE";
}

const initItxAPI = async () => {
  try {
    let itxAPI;
    const { data } = await axios("/api/secrets");

    if (data) {
      const endpoint = await data.itxEndpoint;
      itxAPI = axios.create({
        baseURL: `${endpoint}/rest/itxdialer`,
        withCredentials: true,
        timeout: 10000,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    return itxAPI;
  } catch (error) {
    console.error("Error initializing Axios:", error);
    return null;
  }
};

export const callApplicant = async ({ to, country = "ES" }: CallApplicantProps) => {
  const itxAPI: any = await getAndCacheToken("itxAccessToken", initItxAPI);
  const getEnvironment = () => {
    if (location.hostname === "localhost") return "dev";
    return window.location.hostname.split(".")[1];
  };

  const environment = getEnvironment();
  if (
    country === "ES" &&
    to.substring(0, 4) !== "0034" &&
    to.substring(0, 3) !== "+34" &&
    environment.includes("prod")
  ) {
    to = "+34" + to;
  } else if (country === "SE") {
    to = toPhoneNumberSe(to);
  }

  await itxAPI.get(`/externalcall?callNumber=${to}`);
};
