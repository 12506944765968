export const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/;
    return emailRegex.test(email);
};

export const isPhoneNumberValid = (phoneNumber: string, countryCode: "SE" | "ES"): boolean => {
    switch (countryCode) {
        case "SE":
            const phoneRegex = /^(\+[0-9]{2}|[0-9]{4})?[0-9]{8}$/;
            return phoneRegex.test(phoneNumber);
        case "ES":
            const phoneRegexES = /^(\+[0-9]{2}|[0-9]{4})?[0-9]{9}$/;
            return phoneRegexES.test(phoneNumber);
        default:
            return false;
    }
}