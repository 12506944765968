export function isStringNullOrEmpty(value: string | null | undefined) {
  return !value || value === "";
}

const formatCurrency = (amount: number, country: "ES" | "SE" = "ES") => {
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: country === "SE" ? "SEK" : "EUR",
  }).format(amount ?? 0);
};
export { formatCurrency };

export function formatDateTime(date: string) {
  // Date format options for all queue items.
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "medium", // "full", "long", "medium", or "short"
    timeStyle: "short", // "full", "long", "medium", or "short"
    hour12: false,
  };

  return new Intl.DateTimeFormat("en-GB", options)
    .format(new Date(Date.parse(date)))
    .replace(",", " - ");
}

export const convertChToPx = (ch: number | undefined) => {
  if (!ch) {
    return 0;
  }
  // Assuming 1ch = 8px
  return ch * 8;
};

export const convertVwToPx = (vw: number) => {
  const viewportWidth = window.innerWidth;
  return (vw / 100) * viewportWidth;
};

