import { useState } from "react";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import { ComponentConfig } from "../types";
import { CommonData } from "../../../types/common";
import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";
import { FormText } from "../../../components/Input/FormText";
import { Divider } from "../../../components/Divider";
import { isEmailValid, isPhoneNumberValid } from "../../../utils/validation";
import { ApplicationDetails } from "../../../components/ApplicationDetails";

type ErrorKeys = "phoneNumber" | "emailAddress" | "phoneNumberCoapplicant" | "emailAddressCoapplicant";
type ValidationErrors = Partial<Record<ErrorKeys, string>>;

const ChangeContactInformation = (flow: any) => {
  const { 
    updatedEmailAddress: updatedEmailAddressData, updatedPhoneNumber: updatedPhoneNumberData, updatedEmailAddressCoApplicant: updatedEmailAddressCoApplicantData, 
    updatedPhoneNumberCoApplicant: updatedPhoneNumberCoApplicantData
  } = flow?.task?.data ?? {};
  
  const { agentApiApplication, country } = flow?.flow?.data as CommonData ?? {};
  const { applicant, coapplicant } = agentApiApplication ?? {};
  const { emailAddress, mobileNumber } = applicant ?? {};
  const { emailAddress: emailAddressCoApp, mobileNumber: mobileNumberCoApp } = coapplicant ?? {};

  const [updatedEmailAddress, setUpdatedEmailAddress] = useState<string>(updatedEmailAddressData ?? emailAddress);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>(updatedPhoneNumberData ?? mobileNumber);
  const [updatedEmailAddressCoapplicant, setUpdatedEmailAddressCoapplicant] = useState<string>(updatedEmailAddressCoApplicantData ?? emailAddressCoApp);
  const [updatedPhoneNumberCoapplicant, setUpdatedPhoneNumberCoapplicant] = useState<string>(updatedPhoneNumberCoApplicantData ?? mobileNumberCoApp);

  
  const [error, setError] = useState<ValidationErrors | null>(null);

  const canComplete =
    updatedEmailAddress ||
    updatedEmailAddressCoapplicant ||
    updatedPhoneNumber ||
    updatedPhoneNumberCoapplicant;

  const validate = () => {
    let isValid = true;
    let newErrors: ValidationErrors = {};
  
    if (updatedEmailAddress) {
      if (!isEmailValid(updatedEmailAddress)) {
        newErrors.emailAddress = "Please enter a valid email address for applicant.";
        isValid = false;
      }
    }
  
    if (updatedEmailAddressCoapplicant) {
      if (!isEmailValid(updatedEmailAddressCoapplicant)) {
        newErrors.emailAddressCoapplicant = "Please enter a valid email address for Co-applicant & try again.";
        isValid = false;
      }
    }
  
    if (updatedPhoneNumber) {
      if (!isPhoneNumberValid(updatedPhoneNumber, country)) {
        newErrors.phoneNumber = "Please enter a valid phone number for applicant & try again.";
        isValid = false;
      }
    }
  
    if (updatedPhoneNumberCoapplicant) {
      if (!isPhoneNumberValid(updatedPhoneNumberCoapplicant, country)) {
        newErrors.phoneNumberCoapplicant = "Please enter a valid phone number for Co-applicant & try again.";
        isValid = false;
      }
    }
  
    setError(newErrors);
    return isValid;
  };

  const onComplete = async () => {
    const isValid = validate();
    console.log("isValid: ", isValid);
    if (!isValid)
      return;

    const options: {
      [key: string]: any;
    } = {
      emailAddress: updatedEmailAddress,
      mobileNumber: updatedPhoneNumber,
      emailAddressCoapplicant: updatedEmailAddressCoapplicant,
      mobileNumberCoapplicant: updatedPhoneNumberCoapplicant,
    };

    // Create a new options object without the null values
    Object.keys(options).forEach((key) => {
      if (options[key] == null) {
        delete options[key];
      }
    });

    await flow.trigger(options);
  };

  const onSave = async () => { 
    flow.save({
      updatedEmailAddress: updatedEmailAddress,
      updatedPhoneNumber: updatedPhoneNumber,
      updatedEmailAddressCoapplicant: updatedEmailAddressCoapplicant,
      updatedPhoneNumberCoapplicant: updatedPhoneNumberCoapplicant,
    });
  };

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <div style={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }}>
          <ApplicantInfo applicant={applicant} />
        </div>
      ),
      order: 0,
    },
    {
      component: (
        <FormText 
          label="Email address"
          onChange={(e) => setUpdatedEmailAddress(e)}
          name="emailaddress"
          defaultValue={updatedEmailAddress || ""}
          required
          hasError={error?.emailAddress ? true : false}
          errorMessage={error?.emailAddress}
        />
      ),
      order: 1,
    },
    {
      component: (
        <FormText 
          label="Mobile number"
          onChange={(e) => setUpdatedPhoneNumber(e)}
          name="mobilenumber"
          defaultValue={updatedPhoneNumber || ""}
          required
          hasError={error?.phoneNumber ? true : false}
          errorMessage={error?.phoneNumber}
        />
      ),
      order: 2,
    }
  ];

  if (coapplicant) {
    formConfig.push({
      component: (
        <div style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 28px",
        }}>
          <Divider />
        </div>
      ),
      order: 99,
    },
      {
      component: (
        <div style={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }}>
          <ApplicantInfo applicant={{ ...coapplicant, isCoApplicant: true}} />
        </div>
      ),
      order: 100,
    },
    {
      component: (
        <FormText 
          label="Email address"
          onChange={(e) => setUpdatedEmailAddressCoapplicant(e)}
          name="emailaddressCoapplicant"
          defaultValue={updatedEmailAddressCoapplicant || ""}
          required
          hasError={error?.emailAddressCoapplicant ? true : false}
          errorMessage={error?.emailAddressCoapplicant}
        />
      ),
      order: 101,
    },
    {
      component: (
        <FormText 
          label="Mobile number"
          onChange={(e) => setUpdatedPhoneNumberCoapplicant(e)}
          name="mobilenumber"
          defaultValue={updatedPhoneNumberCoapplicant || ""}
          required
          hasError={error?.phoneNumberCoapplicant ? true : false}
          errorMessage={error?.phoneNumberCoapplicant}
        />
      ),
      order: 102,
    }); // Add form config for coapplicant
  }

  const infoConfig: ComponentConfig[] = [
    {
      component: (
        <ApplicationDetails agentApiApplication={agentApiApplication} country={country} />
      ),
      order: 1,
    }
  ];

  
  return (
    <CommonUserTaskNew
      handleComplete={onComplete}
      handleSave={onSave}
      infoConfig={infoConfig.length > 0 ? infoConfig : undefined }
      formConfig={formConfig}
      overrideMasonryColumns={1}
      disableButtons={!canComplete}
    />
  );
};

export default ChangeContactInformation;
