import styled from "styled-components";
import { FormComponentContainer } from "../style";
import type { FormTextProps } from "./types";

/**
 * Renders a form text.
 * @component
 * @param {Object} props - The component props.
 * - label - The text area label.
 * - name - The text area name.
 * - id - The text area id.
 * - onChange - The function to handle the text area change.
 * - onBlur - The function to handle the text area blur.
 * - defaultValue - The text area default value.
 * - disabled - Indicates if the text area is disabled.
 * - required - Indicates if the text area is required.
 * - size - The text area size.
 * @returns {JSX.Element} The rendered component.
 */
export const FormText = (props: FormTextProps) => {
  const hasErrorStyle = props.hasError ? { border: "1px solid #EF4444" } : {};
  return (
    <FormComponentContainer>
      <StyledLabelContainer>
        <StyledLabelWrap>
          <StyledLabelText>{props.label}</StyledLabelText>
          {!props.required && (
            <StyledOptionalText>(Optional)</StyledOptionalText>
          )}
        </StyledLabelWrap>
        {props.subLabel && (
          <StyledHintLabelWrap>
            <StyledOptionalText>{props.subLabel}</StyledOptionalText>
          </StyledHintLabelWrap>
        )}
      </StyledLabelContainer>
      <StyledInput
        name={props.name}
        id={props.id}
        onChange={(e: any) => props.onChange(e.target.value)}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        required={props.required}
        style={{ ...hasErrorStyle }}
      />
      {props.hasError && props.errorMessage && (
        <StyledErrorContainer>
          <StyledErrorLabel>
            {props.errorMessage}
          </StyledErrorLabel>
        </StyledErrorContainer>
      )}
    </FormComponentContainer>
  );
}

const StyledLabelContainer = styled.div`
  display: flex;
  padding: var(--space-0, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-0, 0px);
  align-self: stretch;
`;

const StyledLabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledLabelText = styled.label`
  color: var(--content-default, #1E1E1E);

  /* Default Strong */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`;

const StyledOptionalText = styled.label`
  color: var(--content-secondary, #6E7686);

  /* Default */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

const StyledHintLabelWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--space-050, 4px);
  align-self: stretch;
`;

const StyledInput = styled.input`
    border: 1px solid #B8C4E0;
    max-width: 100%;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #1E1E1E;
`;

const StyledErrorContainer = styled.div`
  display: flex;
  padding: var(--space-050, 4px) var(--space-150, 12px);
  align-items: flex-start;
  gap: var(--space-0, 0px);
  align-self: stretch;
  border-radius: var(--radius-0, 0px);
  border-left: 2px solid var(--stroke-state-danger-default, #E4251B);
  background: var(--surface-state-danger-lightest, #FFF2F1);
`;

const StyledErrorLabel = styled.label`
  flex: 1 0 0;
  color: var(--content-state-danger-dark, #9F1C15);

  /* Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
`;
