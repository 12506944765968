import styled from "styled-components";

export const AlertWrapper = styled.div`
  display: flex;
  padding: var(--space-150, 12px) var(--space-100, 8px);
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--radius-100, 8px);
  background: var(--surface-state-warning-light, #FFF0CB);
`;

export const AlertContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-0, 0px);
`;

export const AlertInnerWrap = styled.div`
  display: flex;
  padding-left: 4px;
  align-items: center;
`;

export const AlertTextContainer = styled.div`
  display: flex;
  padding: var(--space-0, 0px) var(--space-100, 8px);
  align-items: center;
  gap: var(--space-100, 8px);
`;

export const AlertText = styled.p`
  color: var(--content-state-warning-default, #9E3F00);

  /* Default Strong */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`;

export const AccountRow = styled.div<{isLast?: boolean}>`
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: var(--space-150, 6px) var(--space-100, 8px);
  align-items: center;
  gap: var(--space-100, 8px);
  align-self: stretch;
  border-bottom: ${props => !props.isLast ? "1px solid var(--stroke-secondary, #D4DCEC)" : null};
`;

export const AccountRowContent = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--content-default, #1E1E1E);
  font-variant-numeric: lining-nums tabular-nums;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

export const AccountInfoHeader = styled.h3`
  align-self: stretch;
  color: var(--content-default, #1E1E1E);

  /* Default Strong */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--stroke-secondary, #D4DCEC);
  background: var(--surface-default, #FFF);
  min-width: 500px;
`;

export const AccountHeaderWrapper = styled.div`
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--stroke-secondary, #D4DCEC);
  background: var(--surface-secondary, #F1F3F9);
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 24px;
  align-self: stretch;
`;

export const AccountHeader = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--content-default, #1E1E1E);

  /* Default Strong */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
`;
